import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const SitemapPage = () => (
    <Layout>
        <Seo title={'Nemo\'s Sitemap'} description={''} lang={'en-gb'} />
        <div className="oneCol purple cfx">
            <div className="bdr">
                <div className="bdrTop"></div>
                <div className="bdrMid cfx">


                    <div className="headingArea cfx">

                        <div className="bubble purple cfx cp">
                            <div className="inr">
                                <h1>Sitemap</h1>
                            </div>
                            <div className="bot"></div>
                        </div>

                        <div className="intro">
                            <p className="leading"></p>
                        </div>
                    </div>


                    <div className="sitemap purple">
                        <h2>Existing Customers</h2>
                        <ul>
                            <li>
                                <a href="/about-us">About us</a>
                            </li>
                            <li>
                                <a href="/contact-us">Contact us</a>
                                <ul>
                                    <li>
                                        <a href="/contact-us/complaints">Nemo Personal Finance Complaints Procedure</a>
                                    </li>
                                </ul>
                            </li>
                            
                            <li><a href="/help-with-repayments">Support</a>
                                <ul>
                                <li><a href="/support/extra-help-when-you-need-it">Extra help when you need it</a></li>
                                <li><a href="/support/homes-for-ukraine" className={'selected'}>Homes for Ukraine</a></li>
                                </ul>
                            </li>
                            
                        </ul>
                    </div>
                </div>
                <div className="bdrBot"></div>
            </div>
        </div>
    </Layout>
);

export default SitemapPage;